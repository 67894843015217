import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { withCookies, Cookies } from "react-cookie";
import { Button, Card, ListGroup, ListGroupItem } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { datadogRum } from "@datadog/browser-rum";

const Container = styled.div`
  width: 100%;
  height: 813px;
  color: black;
  display: flex;
  align-items: center;
`;
const Info = styled.div`
  width: 60%;
  margin: auto;
  font-size: 2rem;
  line-height: 2;
  text-align: center;
  color: white;
`;

const popUp = () => {
  toast.info(
    <div>
      <h3>{"🎁 중요 공지"} </h3>
      {"ChatGPT를 이용해서, 키워드를 더 빠르고 효율적으로 사용해보세요!"}
      <br />
      <br />
      {"서비스가 정상화 되었습니다. 기다려주셔서 감사합니다."}
      <br />
      <br />
      {"N리스트를 직관적으로 확인할 수 있게 광고/통합웹/블로그/카페/티스토리로 표준화 했습니다."}
      <br />
      <br />
      {"이를 통해 직관적으로 내 포스팅이 노출될 가능성을 확인할 수 있습니다."}
      <br />
      <br />
      {"한 달 블로그 발행 수치 버그가 정상화 되었습니다."}
      <br />
      <br />
      {
        "빠르게 상위 노출되는 콘텐츠를 분석해보세요, 특히 카페 상위 노출되면 댓글 게릴라도 고려해보세요!"
      }
      <br />
      <br />
    </div>,
    {
      autoClose: 50000,
    }
  );
};

const MyPage = () => {
  const [logOut, setLogout] = useState(false);
  const cookie = new Cookies({});
  const logOutF = () => {
    toast.error(<div>{"로그아웃 되었습니다."}</div>, {
      autoClose: 3000,
    });
    cookie.remove("email");
    cookie.remove("tier");
    cookie.remove("expire");
    cookie.remove("apiKeyValue");
    cookie.remove("confirm");
    cookie.remove("check");
    setLogout(true);
  };
  datadogRum.setUser({
    name: cookie.get("email"),
    id: cookie.get("check"),
    plan: cookie.get("tier"),
    expire: cookie.get("expire"),
  });
  datadogRum.setGlobalContextProperty("global_context", {
    name: "hyunjin.lee",
    payment: 70000,
    matching: 1,
    like: 5,
    liked: 0,
  });
  useEffect(() => {
    popUp();
  }, []);

  return logOut ? (
    <Redirect to="/auth" />
  ) : (
    <Container>
      {cookie.get("check") ? (
        // <Info>
        <Card
          style={{
            width: "43rem",
            height: "34rem",
            margin: "auto auto",
            backgroundColor: "#141414",
            borderColor: "white",
            color: "white",
            fontSize: "1.6rem",
          }}
        >
          <Card.Body>
            <Card.Title
              style={{
                fontSize: "1.2em",
                textAlign: "center",
                color: "#FFF201",
              }}
            >
              안내사항
            </Card.Title>
            <Card.Text style={{ lineHeight: "150%" }}>
              로그인은 한 달 동안 지속되며, 공용 PC를 사용할 경우 반드시
              로그아웃 해주시기 바랍니다.
              <br></br>
              6개월 동안 접속이 없으면 계정은 초기화 됩니다.
              <br></br>
              후원 항목을 통해 각 서비스 제한 항목을 확인해주세요.
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem
              style={{
                backgroundColor: "#141414",
                marginLeft: "20%",
                marginTop: "1%",
              }}
            >
              {`계정명: ${cookie.get("email")}`}
            </ListGroupItem>
            <ListGroupItem
              style={{
                backgroundColor: "#141414",
                marginLeft: "20%",
              }}
            >
              {`서비스: ${cookie.get("tier")}`}
            </ListGroupItem>
            <ListGroupItem
              style={{
                backgroundColor: "#141414",
                marginLeft: "20%",
                marginBottom: "1%",
              }}
            >
              {cookie.get("apiKeyValue") != "false" ? (
                <>
                  {cookie.get("tier") == "Pro" ? (
                    <>
                      <p>{`종료일: ${cookie.get("expire")}`}</p>
                      <Helmet>
                        <script>
                          {window.gtag("event", "conversion", {
                            send_to: "AW-963158908/GHVfCNvZmIQDEPzGossD",
                          })}
                        </script>
                      </Helmet>
                    </>
                  ) : (
                    <>
                      <p>{`종료일: ${cookie.get("expire")}`}</p>
                      <Helmet>
                        <script>
                          {window.gtag("event", "conversion", {
                            send_to: "AW-963158908/62N2CKO2m4QDEPzGossD",
                          })}
                        </script>
                      </Helmet>
                    </>
                  )}
                </>
              ) : (
                <p>{`종료일 : ${cookie.get("expire")} (서비스 기간 만료)`}</p>
              )}
            </ListGroupItem>
          </ListGroup>
          <Card.Body
            style={{ margin: "auto", padding: "1rem 0", height: "1rem" }}
          >
            <Button
              variant="danger"
              style={{ width: "11rem", height: "3rem" }}
              onClick={logOutF}
            >
              로그아웃
            </Button>
          </Card.Body>
        </Card>
      ) : (
        <Info>
          <strong style={{ color: "#FFF20A", fontSize: "2.5rem" }}>
            황금키워드
          </strong>
          는 가입 후 사용 가능합니다. <br></br>
          가입이 안 될 경우,{" "}
          <strong style={{ color: "#FFF20A", fontSize: "2.5rem" }}>
            master@gold-keyword.info
          </strong>
          로 메일을 보내주세요. <br></br>
          예시) 가입 ID: xxx@naver.com / 서비스 시작일: 2020-06-22{" "}
        </Info>
      )}
    </Container>
  );
};

export default withCookies(MyPage);
