import React from "react";
import styled from "styled-components";
const Container = styled.div`
  width: 100%;
  margin: 0 auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextForm = styled.div`
  font-size: 1.1rem;
  width: 50%;
  margin: 1% auto 1% auto;
  @media only screen and (max-width: 1028px) {
    width: 80%;
  }
`;

const Text = styled.div`
  margin-bottom: 1rem;
  letter-spacing: 0.1rem;
  line-height: 1.5rem;
`;

const Image = styled.div`
  display: flex;
  width: 90%;
  margin: 0 auto 0 auto;
`;

const Img = styled.img`
  width: 100%;
`;

export default () => {
  return (
    <Container>
      <TextForm>
        <Text>
          <p data-ke-size="size18">
            <b>노하우 eBook은 황금키워드의 활용 방법입니다.</b> <br />
            <br />
          </p>
          <p data-ke-size="size18">
            이제 막 애드센스, 애드포스트, 쿠팡파트너스나 제휴마케팅을 시작하신
            분들은 황금키워드를 잘 활용하기 어렵습니다. <br />
            <br />
            그래서 여러분들이 황금키워드를 잘 이용하고 수익을 낼 수 있도록
            도와주기 위해 황금키워드 사용에 대한 노하우를 담은 eBook을
            만들었습니다.
          </p>
          <p data-ke-size="size18">
            <br />
            <br />
            [그럼 정말 수익을 보장할 수 있는가?]
            <br />
            <br />
            eBook 구매 후 재구매률 또한 30% 수준입니다.
            <br />
            <br />
            타겟은 황금키워드로 수익을 어떻게 낼 지 감이 안 잡히시는 분들입니다.{" "}
            <br />
            <br />
            이미 하루에 1만원 이상 수익을 내시는 분들은 몇 가지 정보 빼고는 굳이
            구매하실 필요 없습니다. <br />
            <br />
            이미 제 지인 두 명을 대상으로 실험해봤습니다. <br />
            <br />
            어느정도 셋팅이 되어 있다는 가정 하에 하루 30분 투자로 한 달만에 월
            20만원 이상 벌 수 있는 확률은 80% 이상이라고 생각됩니다.
            <br />
            <br />
            시급으로 1.5만원 수준이네요. 그리고 작성한 것들이 쌓일수록 더
            높아집니다. 사기꾼들처럼 허황된 금액을 말씀드리지 않습니다.
            <br />
            <br />
            물론 사람마다 다르며, 마케팅 감각은 필요합니다.
            <br />
            <br />
            먼저 인터넷 검색만 해도 나오는 흔해 빠진 정보는 넣지 않았습니다.
            <br />
            <br />
            기본적인 블로그 구성 및 애드센스 사용 방법은 검색을 통해 무료로
            충분히 찾으실 수 있습니다.
            <br />
            <br />
            목차를 보고 잘 고려하신 뒤에 구매는 신중히 하시기 바랍니다. <br />
            <br />
            <br />
            <p>[목차]</p>
            1. 황금키워드 사용 전 해야할 일<br />
            1-1. 쉽게 애드센스/애드포스트 승인 받는 노하우 <br></br>
            <br />
            1-2. 이거 하나만으로 Active View 3% 이상 늘어납니다. <br /> <br />
          </p>
          <p data-ke-size="size18"> </p>
          <p data-ke-size="size18">1-3. XXX XX은 필수! </p>
          <br />
          <p data-ke-size="size18"> </p>
          <p data-ke-size="size18">
            1-4. 블로그 저품질 낮추고, 품질 높이기
            <br />
            <br />
          </p>
          <p data-ke-size="size18">
            1-5. 티스토리 네이버, 다음 통누락 해결 방법 <br />
            - 최초 네이버 통누락 해결한 사람이 알려드림
            <br />
            (이거 하나만으로도 가치 있음) <br />
            <Image>
              <Img src="/naver.png" />
            </Image>
          </p>
          <p data-ke-size="size18"> </p>
          <p data-ke-size="size18">
            <br />
            2. 황금키워드 활용
            <br />
            2-1. 이게 꿀키워드 <br />
            - 황금키워드에서 키워드 고르는 노하우 <br />
            - 키워드 활용 방법
            <br />
            <br />
            2-2. 키워드 제목은 어떻게 지을까? <br />
            <br />
            2-3. 카테고리별 키워드 15분 만에 글 쓰는 방법 <br />
            <br />
            2-4. 쉽게 애드센스 수익 20% 이상 늘리는 기술 <br />
            <br />
            2-5. 수익이 나는 포스팅을 분석하고, 확장하자 <br />
            <br />
            2-6. 성과가 바로 나오는 쿠팡파트너스/제휴마케팅 공략
            <br />
            <br />
            여러분들의 건승을 빕니다. <br />
            <br />
          </p>
        </Text>
      </TextForm>
    </Container>
  );
};
