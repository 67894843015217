import React from "react";
import styled, { keyframes } from "styled-components";
import Helmet from "react-helmet";

const Container = styled.div`
  height: 757px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all ease 1s;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }`;

const Rotate = styled.span`
  display: inline-block;
  animation: ${rotate} 2s linear infinite;
  padding: 2rem 1rem;
  font-size: 7rem;
`;

export default () => (
  <Container>
    <Helmet>
      <title>키워드 찾는 중...</title>
    </Helmet>
    <Rotate>
      <span role="img" aria-label="Loading">
        🎁
      </span>
    </Rotate>
    <h1>최소 5초~최대 15초 소요</h1>
  </Container>
);
