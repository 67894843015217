import React from "react";
import styled from "styled-components";
import Price from "Components/Price";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* height: 813px; */
  margin-top: 3% auto 0 auto;
`;

const Title = styled.div`
  font-size: 1.5rem;
  width: 60%;
  padding-bottom: 50px;
  @media only screen and (max-width: 1028px) {
    width: 95%;
  }
`;

const Title2 = styled.div`
  font-size: 1.3rem;
  width: 60%;
  padding-bottom: 50px;
  @media only screen and (max-width: 1028px) {
    width: 95%;
  }
`;

export default () => {
  return (
    <Container className="payment">
      <Price />
      <Title>
        <br></br>
        1. 네이버 스마트스토어 결제(22.12부터 변경)
        <br></br>
        2. 결제 시{" "}
        <strong style={{ color: "#FFF20A", fontSize: "1.4rem" }}>
          기타메모
        </strong>{" "}
        또는{" "}
        <strong style={{ color: "#FFF20A", fontSize: "1.4rem" }}>
          master@gold-keyword.info
        </strong>
        로 아래 내용 참고해서 전달주시기 바랍니다.
        <br></br>
        1) 가입 ID: xxx@naver.com / 2) 원하는 서비스: 1개월, 3개월, eBook
        <br></br>
        유료 회원 등록은 1일 정도 소요되며, 그 이상 지연 시 메일로 문의
        부탁드립니다.
        <br></br>
        <br></br>
        문의는 스마트스토어 대신 메일로 요청주시기 바랍니다. 스마트스토어를 통한
        답변은 지연될 수 있습니다.
        <br></br>
        <br></br>
        체험 사용 후 블로그나 온라인 비즈니스 커뮤니티에 후기를 남겨주시고, 인증
        메일을 보내주시면 1개월 이용권을 드립니다. <br></br>
      </Title>
    </Container>
  );
};
