/* eslint-disable no-fallthrough */
import React, { useState, useEffect } from "react";
import LoaderSmall from "./LoaderSmall";
import styled from "styled-components";
import { keywordSearchApi } from "api";
import { withCookies, Cookies } from "react-cookie";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IMG = styled.img`
  padding-right: 1rem;
  width: 40px;
`;

const GetDetailInfoWeb = (keyword, apiKey) => {
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  async function detailInfo() {
    try {
      setLoading(true);
      const {
        data: { body: results },
      } = await keywordSearchApi.detailInfoWebKeyword(keyword, apiKey);
      setResult(results);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    detailInfo();
  }, [keyword]);
  return { result, loading, error };
};

const DetailInfoWeb = ({ keyword }) => {
  const cookie = new Cookies({});
  const apiKey = cookie.get("apiKeyValue");
  const detailInfoWeb_result = GetDetailInfoWeb(keyword, apiKey);
  console.log(detailInfoWeb_result);
  return detailInfoWeb_result.loading ? (
    <>
      <LoaderSmall />
    </>
  ) : (
    <>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ width: "10%" }}>
                <div style={{ fontWeight: "bold" }}>N/D사 웹사이트 노출</div>
              </TableCell>
              <TableCell align="center" style={{ width: "15%" }}>
                <div style={{ fontWeight: "bold" }}>이름</div>
              </TableCell>
              <TableCell align="center" style={{ width: "35%" }}>
                <div style={{ fontWeight: "bold" }}>제목</div>
              </TableCell>
              <TableCell align="center" style={{ width: "5%" }}>
                <div style={{ fontWeight: "bold" }}>발행일</div>
              </TableCell>
              <TableCell align="center" style={{ width: "10%" }}>
                <div style={{ fontWeight: "bold" }}>포스팅 및 댓글</div>
              </TableCell>
              <TableCell align="center" style={{ width: "15%" }}>
                <div style={{ fontWeight: "bold" }}>조회 및 방문</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {detailInfoWeb_result.result.map(
              (
                {
                  host_type,
                  host_name,
                  subtitle,
                  sub_time,
                  host_count,
                  post_count,
                  host_url,
                  img,
                },
                i
              ) => (
                <TableRow key={i}>
                  <TableCell align="center">
                    <Title>
                      <div>
                        <IMG src={img} />
                      </div>
                      <div>{host_type}</div>
                    </Title>
                  </TableCell>
                  <TableCell align="center">{host_name}</TableCell>
                  <TableCell align="center">
                    <a
                      href={host_url}
                      target="_blank"
                      style={{ color: "rgb(1, 87, 155)" }}
                    >
                      {subtitle}
                    </a>
                  </TableCell>
                  <TableCell align="center">{sub_time}</TableCell>
                  <TableCell align="center">{post_count}</TableCell>
                  <TableCell align="center">{host_count}</TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default withCookies(DetailInfoWeb);
