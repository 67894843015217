import React from "react";
import SearchPresenter from "./SearchPresenter";
import { withCookies, Cookies } from "react-cookie";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";

const SearchContainer = () => {
  const cookie = new Cookies({});
  const apiKeyValue = cookie.get("apiKeyValue");
  if (apiKeyValue === "false") {
    toast.error(<div>{"서비스 기간 만료"}</div>, {
      autoClose: 3000,
    });
  }
  return apiKeyValue !== "false" ? <SearchPresenter /> : <Redirect to="/auth" />;
};

export default withCookies(SearchContainer);
