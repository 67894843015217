import React from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import { withCookies, Cookies } from "react-cookie";

const Header = styled.header`
  color: white;
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  /* display: flex; */
  display: grid;
  grid-template-columns: 4fr 2.5fr 8fr;
  align-items: center;
  background-color: #202124;
  z-index: 10;
  box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.8);
  ul {
    justify-content: flex-end;
  }
`;

const List = styled.ul`
  display: flex;
  margin-right: 2%;
`;

const Item = styled.li`
  width: 15%;
  height: 50px;
  text-align: center;
  border-bottom: 3px solid
    ${(props) => (props.current ? "#fff200" : "transparent")};
  transition: border-bottom 0.5s ease-in-out;
  margin: 0 1%;
`;

const AItem = styled.li`
  width: 20%;
  height: 50px;
  text-align: center;
  border-bottom: 3px solid
    ${(props) => (props.current ? "#fff200" : "transparent")};
  transition: border-bottom 0.5s ease-in-out;
  margin: 0 1%;
`;

const SLink = styled(Link)`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  width: 100%;
  color: #fff200;
  -moz-text-shadow: 0 0 2px #000;
  -webkit-text-shadow: 0 0 2px #000;
  font-weight: bold;
  font-weight: 700;
  &:nth-child(1) {
    font-size: 1.6rem;
  }
`;

export default withCookies(
  withRouter(({ location: { pathname } }) => {
    const cookie = new Cookies({});
    return (
      <Header className="header">
        <Title current={pathname === "/"}>
          <SLink to="/">황금키워드</SLink>
        </Title>
        <List></List>
        {cookie.get("confirm") ? (
          <List>
            <Item current={pathname === "/mypage"}>
              <SLink to="/mypage" title="만료일 확인">
                내 정보
              </SLink>
            </Item>
            <Item current={pathname === "/list"}>
              <SLink to="/list" title="검색량 대비 문서수가 적은 일반 키워드">
                일반 키워드
              </SLink>
            </Item>
            <Item current={pathname === "/productlist"}>
              <SLink
                to="/productlist"
                title="검색량 대비 문서수가 적은 상품 키워드"
              >
                상품 키워드
              </SLink>
            </Item>
            <Item current={pathname === "/commonlist"}>
              <SLink to="/commonlist" title="카페 게릴라용 상품 키워드">
                일반 게릴라
              </SLink>
            </Item>
            <Item current={pathname === "/sublist"}>
              <SLink to="/sublist" title="카페 게릴라용 상품 키워드">
                상품 게릴라
              </SLink>
            </Item>
            <Item current={pathname.includes("/search")}>
              <SLink to="/search">키워드 검색</SLink>
            </Item>
            <Item current={pathname === "/payment"}>
              <SLink to="/payment">구매하기</SLink>
            </Item>
          </List>
        ) : (
          <List>
            <AItem current={pathname === "/"}>
              <SLink to="/" title="로그인 시 자동회원 가입">
                로그인
              </SLink>
            </AItem>
          </List>
        )}
      </Header>
    );
  })
);
