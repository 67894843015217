import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { AuthApi } from "api";
import Button from "Components/Button";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { withCookies, useCookies } from "react-cookie";
import { Carousel } from "react-bootstrap";
import Info from "Components/Info";
import Payment from "Components/Payment";
import { datadogRum } from "@datadog/browser-rum";

const Container = styled.div`
  width: 100%;
  height: 800px;
  display: flex;
  flex-direction: column;
  /* margin-bottom: 7%; */
`;

const Box = styled.div`
  ${(props) => props.theme.whiteBox}
  border-radius:5px;
  width: 100%;
  max-width: 350px;
  border: 1px solid #e6e6e6;
`;

const FormContainer = styled(Box)`
  margin: 0 auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40%;
  form {
    width: 80%;
    input {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 7px;
      }
    }
    button {
      margin-top: 10px;
      margin-bottom: 15px;
      background-color: ${(props) => (props.current ? "#07BC0C" : "#303f9f")};
      /* background-color: #303f9f; */
    }
  }
`;

const Text = styled.div`
  padding-bottom: 7%;
  text-align: center;
  font-size: 1.3rem;
  letter-spacing: 1.7px;
  line-height: 2.3rem;
`;

const Input = styled.input`
  border: 0;
  border-radius: 4px;
  height: 35px;
  font-size: 1rem;
  padding: 0 15px;
  text-align: center;
`;

const Subject = styled.div`
  width: 53%;
  margin: 8% auto 2% auto;
  font-size: 1.4rem;
  line-height: 2;
  @media only screen and (max-width: 1028px) {
    font-size: 1.2rem;
  }
`;

const Auth = () => {
  const [cookies, setCookie] = useCookies([
    "email",
    "expire",
    "check",
    "confirm",
    "tier",
    "apiKeyValue",
  ]);
  const [email, setEmail] = useState("");
  const { register, handleSubmit, reset } = useForm();
  const onSubmit = async (data) => {
    if (data.email) {
      const req_email = data.email.split("@");
      if (req_email[1] !== "naver.com") {
        toast.error(
          <div>
            {"올바른 메일인지 확인해주세요."}
            <br />
            {"네이버 메일만 사용 가능합니다!"}
          </div>,
          {
            autooClose: 5000,
          }
        );
      } else {
        try {
          toast.error(<div>{"인증코드 전송 중 입니다."}</div>, {
            autoClose: 2500,
          });
          const {
            data: { body: res },
          } = await AuthApi.login(data.email);
          setEmail(res.email);
          if (res.check) {
            toast.success(
              <div>
                {"등록한 메일로 인증코드를 보냈습니다."}
                <br />
                {"스팸 메일함도 확인해주세요!"}
              </div>,
              {
                autoClose: 5000,
              }
            );
          } else {
            toast.error(
              <div>
                {"로그인 에러입니다. 새로고침 후 다시 시도해주시기 바랍니다."}
                <br />
                {"새로고침 후에도 로그인 에러 시 메일 보내주시길 바랍니다."}
                e0e6a4
              </div>,
              {
                autoClose: 5000,
              }
            );
          }
          reset("");
        } catch {
          toast.error(
            <div>
              {"로그인 에러입니다. 새로고침 후 다시 시도해주시기 바랍니다."}
              <br />
              {"새로고침 후에도 로그인이 에러 시 메일 보내주시길 바랍니다."}
            </div>,
            {
              autoClose: 5000,
            }
          );
        }
      }
    } else if (data.secretKey) {
      try {
        const {
          data: { body: res },
        } = await AuthApi.confirm(email, data.secretKey);
        console.log(res);
        if (res.check) {
          toast.success(<div>{"로그인 되었습니다."}</div>, {
            autoClose: 3000,
          });
          setCookie("check", res.check, { maxAge: 2592000 });
          setCookie("tier", res.tier, { maxAge: 2592000 });
          setCookie("email", res.email, { maxAge: 2592000 });
          setCookie("expire", res.expire, { maxAge: 2592000 });
          setCookie("confirm", res.confirm, { maxAge: 2592000 });
          setCookie("apiKeyValue", res.apiKeyValue, { maxAge: 2592000 });
          // datadogRum.init({
          //   applicationId: "26c7d5c9-2f05-47f9-9d78-6c8e490f9d85",
          //   clientToken: "pub29804c1fe7159ab37829b42586da649a",
          //   site: "datadoghq.com",
          //   service: "gold-keyword.com",
          //   env: "prod",
          //   version: "1.0.0",
          //   sampleRate: 100,
          //   sessionReplaySampleRate: 100,
          //   trackInteractions: true,
          //   trackResources: true,
          //   trackLongTasks: true,
          //   defaultPrivacyLevel: "mask-user-input",
          //   beforeSend: (event) => {
          //     event.view.url = event.view.url.replace("/#", "");
          //   },
          // });
          datadogRum.setUser({
            name: res.email,
            id: res.check,
            plan: res.tier,
            expire: res.expire,
          });
        } else {
          toast.error(
            <div>
              {"인증코드가 틀렸습니다."}
              <br />
              {"인증코드 확인 불가 시 메일 보내주시길 바랍니다."}
            </div>,
            {
              autoClose: 5000,
            }
          );
        }
      } catch {
        toast.error(
          <div>
            {"로그인 에러입니다."}
            <br />
            {"새로고침 후에 로그인 하시기 바랍니다."}
            <br />
            {"새로고침 후에도 로그인 불가 시 메일 보내주시길 바랍니다."}
          </div>,
          {
            autoClose: 5000,
          }
        );
      }
    }
  };

  return !cookies.check ? (
    <Carousel interval={null} className="content">
      <Carousel.Item>
        <Container>
          <Subject>
            <strong style={{ color: "#FFF20A", fontSize: "1.5rem" }}>
              황금키워드
            </strong>
            는 블로그(애드센스/애드포스트/쿠팡파트너스), 바이럴마케팅,
            스마트스토어를 위한 서비스로 다양한 곳에서 검색량이 많고, 문서수는
            적은{" "}
            <strong style={{ color: "#FFF20A", fontSize: "1.5rem" }}>
              최적의 키워드
            </strong>
            를 제공하는 서비스입니다.
            <br></br>
            <strong style={{ color: "#FFF20A", fontSize: "1.5rem" }}>
              황금키워드
            </strong>
            를 통해{" "}
            <strong style={{ color: "#FFF20A", fontSize: "1.5rem" }}>
              상위노출{" "}
            </strong>
            확률을 높일 수 있습니다. 구글 크롬 브라우저를 이용해주세요.
          </Subject>
          {email ? (
            <FormContainer current={email}>
              <Text>
                <h3>{email}</h3>
                <h3>등록한 메일 확인 후</h3>
                <h3>6자리 인증코드 입력</h3>
                <h3>스팸 메일함도 확인</h3>
              </Text>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Input
                  type="text"
                  name="secretKey"
                  ref={register}
                  placeholder="6자리 인증코드 입력"
                  valie=""
                />
                <Button text={"인증코드 확인"} />
              </form>
              <Link to="/payment" style={{ color: "#FFF201" }}>
                <strong>회원 등급별 혜택 확인</strong>
              </Link>
            </FormContainer>
          ) : (
            <FormContainer>
              <Text>
                <h3>황금키워드🎁</h3>
                <h3>로그인 시 자동 회원가입</h3>
                [네이버 이메일]만 사용 가능
              </Text>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Input
                  type="email"
                  name="email"
                  ref={register}
                  placeholder="Email 주소 입력(id@naver.com)"
                />
                <Button text={"로그인"} />
              </form>
              <Link to="/payment" style={{ color: "#FFF201" }}>
                <strong>회원 등급별 혜택 확인</strong>
              </Link>
            </FormContainer>
          )}
        </Container>
      </Carousel.Item>
      <Carousel.Item>
        <Info />
      </Carousel.Item>
      <Carousel.Item>
        <Payment />
      </Carousel.Item>
    </Carousel>
  ) : (
    <Redirect to="/mypage" />
  );
};

export default withCookies(Auth);
