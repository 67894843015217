import React from "react";
import ReactDOM from "react-dom";
import App from "Components/App";
import { CookiesProvider } from "react-cookie";
import "./api";
import { datadogRum } from "@datadog/browser-rum";

datadogRum.init({
  applicationId: "26c7d5c9-2f05-47f9-9d78-6c8e490f9d85",
  clientToken: "pub29804c1fe7159ab37829b42586da649a",
  site: "datadoghq.com",
  service: "gold-keyword",
  env: "prod",
  version: "1.0.0",
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "allow",
  beforeSend: (event, context) => {
    event.view.url = event.view.url.replace("/#", "");
    // if (event.resource.type === "image") {
    //   event.context = {
    //     ...event.context,
    //     response: context,
    //   };
    // }
    // console.log(event.resource.type);
    // if (event.resource.type === "image") {
    //   console.log(event);
    //   console.log(context);
    // }
    if (event.type === "resource" && event.resource.type == "xhr") {
      // console.log("DD XHR EVENT");
      // console.log(event);
      // console.log("DD XHR CONTEXT");
      // console.log(context);
      event.context = {
        ...event.context,
        xhr_body: context.xhr.response.substr(0, 20),
      };
      // console.log("DD RETURN CONTEXT");
      // console.log(event.context);
    }
  },
});

ReactDOM.render(
  <CookiesProvider>
    <App />
  </CookiesProvider>,
  document.getElementById("root")
);
