import React from "react";
import AppRouter from "Components/Router";
import GlobalStyles from "Components/GlobalStyles";
import { HashRouter as Router } from "react-router-dom";
// import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Header from "Components/Header";
import Footer from "Components/Footer";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    // Use the system font.
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme} className="page">
      <GlobalStyles />
      <Router>
        <Header />
        <AppRouter />
      </Router>
      <ToastContainer position={toast.POSITION.TOP_CENTER} />
      <Footer className="footer" />
    </ThemeProvider>
  );
};
export default App;
