import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import "bootstrap/dist/css/bootstrap.min.css";
const globalStyles = createGlobalStyle`
    ${reset};
    a{
        text-decoration:none;
        color:inherit;
    }
    *{
        box-sizing:border-box;
    }
    body{
        font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 1rem;
        background-color: #202124;
        color: white;
        padding-top: 50px;
        letter-spacing: 0.01071em;
        display: grid;
        grid-template-areas:
            "header"
            "content"
            "footer";
    }
`;

export default globalStyles;
