import React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  margin: 0 auto 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const TextForm = styled.div`
  font-size: 1.1rem;
  width: 50%;
  margin: 1% auto 1% auto;
  @media only screen and (max-width: 1028px) {
    width: 90%;
  }
`;

const Text = styled.div`
  margin-bottom: 1rem;
  letter-spacing: 0.1rem;
  line-height: 1.5rem;
`;

const Image = styled.div`
  display: flex;
  width: 90%;
  margin: 0 auto 0 auto;
`;

const Img = styled.img`
  width: 100%;
`;

export default () => {
  return (
    <Container>
      <Helmet>
        <title>황금키워드</title>
      </Helmet>
      <TextForm>
        <Text>
          황금키워드는 가입이 필요 없습니다.
          <br />
          가입 즉시 가입하신 메일로 인증코드가 발송되고, 인증코드로 회원가입 및
          로그인이 동시에 이루어집니다.
          <br />
          <br />
          황금키워드는 직관적으로 쉽게 사용할 수 있습니다.
          <br />
          <br />
          크게 두 가지 기능을 제공합니다.
          <br />
          1) 일반/상품 키워드 리스트(블로그용, 게릴라용)
          <br />
          <br />
          2) 키워드 검색 기능
          <br />
          <br />
          [키워드 리스트]
          <br />
          1. 매일 1,000~2,000개의 검색량 대비 문서수가 적은 황금키워드 업데이트
          <br />
          2. T블로그, N블로그 전체 포스팅 발행량 / 한 달 간 포스팅 발행량
          <br />
          3. 쇼핑몰 개수
          <br />
          4. 한 달 간 PC/모바일 예상 검색량
          <br />
          5. 검색량 대비 문서 및 쇼핑몰 비율
          <br />
          6. N사, D사 키워드 검색에 따른 모바일 섹션 배치 순서
          <br />
          7. 키워드 광고 단가 및 클릭률
          <br />
          <br />
          게릴라용 키워드 리스트는 기능은 동일하지만 문서수와 상관 없이 해당
          키워드 검색 시 CAFE가 상위 노출되고 있는 키워드들을 제공합니다.
          <br />
          <br />
          따라서 해당 기능을 통해 게릴라 방식으로 자신의 블로그로 유입 시키거나,
          쿠팡파트너스나 텐핑과 같은 제휴마케팅을 통해 수익을 올릴 수 있습니다.
          <br />
          <br />
          제휴마케팅 "텐핑"이란?{" "}
          <a
            href={`http://Aoille.me/t6o6h0bhmd`}
            target="_blank"
            style={{ color: "#ffe92b", fontWeight: "700" }}
          >
            http://Aoille.me/t6o6h0bhmd
          </a>
          <br />
          <br />
          키워드 리스트는 3일 동안 중복되지 않는 새로운 키워드를 제공합니다.
          <br />
          <br />
          <Img style={{ height: "500px" }} src="/info1.png" />
          <br />
          <br />
          8. N/D사 VIEW와 웹사이트 영역 상위 노출 정보(발행일, 포스팅 및 댓글
          수, 조회 및 방문 수)
          <br />
          <Img style={{ height: "350px" }} src="/info_detail.png" />
          <br />
          <br />
          9. 최근 1달, 최근 3년 검색량, 일주일, 성별, 연령별 검색 통계
          <br />
          <Img style={{ height: "500px" }} src="/info2.png" />
          <br />
          <br />
          10. 소팅 기능
          <br />
          <Img style={{ height: "300px" }} src="/info10.png" />
          <br />
          <Img style={{ height: "450px" }} src="/info11.png" />
          <br />
          <br />
          각 센션(웹사이트, CAFE 등) 이나 특정 키워드만 뽑아서 리스트를 확인할
          수 있습니다.
          <br />
          <br />
          이 기능을 통해 자신의 타겟팅 하는 섹션이나 키워드만 중점적으로 확인이
          가능합니다.
          <br />
          <br />
          <br />
          12. 키워드 저장 기능(후원 시 지원)
          <br />
          <Img style={{ height: "500px" }} src="/info9.png" />
          <br />
          <br />
          <br />
          이 처럼 키워드 리스트 기능을 통해 매일 다양한 키워드들의 정보를 한
          눈에 알 수 있고, 키워드들을 저장할 수 있습니다.
          <br />
          <br />
          다양한 통계 정보를 활용하여 숏테일/롱테일 키워드를 구분할 수 있고,
          성별/연령 정보를 확인하여 쉽게 타겟팅도 가능합니다.
          <br />
          <br />
          키워드 리스트 기능은 온라인 마케팅을 하시는 모든 분들의 키워드 고민을
          덜어주고 있습니다.
          <br />
          <br />
          <br />
          [키워드 검색 기능]
          <br />
          키워드 검색 기능은 다른 키워드 검색 서비스와 차원이 다른 수준의
          서브키워드를 제공합니다.
          <br />
          <br />
          예를 들어 "재난지원금"을 검색 했을 때 황금키워드는 173개의
          서브키워드를 찾았습니다.
          <br />
          <br />
          <Img style={{ height: "450px" }} src="/info3.png" />
          애드센스를 하시는 분이라면 재난지원금이 얼마나 좋은 키워드인지 아실
          겁니다.
          <br />
          <br />
          또한 쿠팡파트너스나 스마트스토어 운영자분들 혹은 마케터분들이라면
          상품이나 마케팅 키워드에 대한 고민이 있으실텐데요.
          <br />
          <br />
          이 분들 역시 황금키워드의 검색 기능의 가치를 느낄 수 있습니다.
          <br />
          <br />
          에어컨을 검색했을 때 황금키워드는 247개의 서브키워드를 찾아냈습니다.
          <br />
          <br />
          <Img style={{ height: "450px" }} src="/info5.png" />
          <br />
          황금키워드는 최대 7초 이내에 200여개의 서브키워드를 찾아내고, 분석된
          정보까지 제공합니다. <br />
          <br />
          <br />
          아래는 잘 알려진 다른 키워드 서비스에서 "재난지원금"과 "에어컨"을
          검색했을 때 결과입니다.
          <Img style={{ height: "200px" }} src="/info7.png" />
          <Img style={{ height: "200px" }} src="/info8.png" />
          <br />
          <br />
          황금키워드는 타 키워드 서비스 대비 최대 12배 이상 서브키워드를
          제공하고, 분석된 서브키워드들의 다양한 정보를 한 번에 확인할 수
          있습니다.
          <br />
          <br />
          <br />
          [활용 방법]
          <br />
          1. 키워드 리스트의 기능 활용
          <br />
          블로그 포스팅의 경우 B지수, B지수(Month)로 내림차순 하여 키워드를
          찾고, 포스팅 후 블로그 노출 여부를 확인합니다.
          <br />
          <br />
          블로그 지수에 따라 내 블로그가 어느 정도의 B지수, B지수(Month)까지
          노출되는 지 확인합니다.
          <br />
          <br />
          확인 후 자신의 블로그 지수에 맞는 B지수를 찾아 글들을 쓰고, 점차
          블로그 지수가 높아지면 B지수 또한 서서히 높이시길 바랍니다.
          <br />
          <br />
          <br />
          2. 키워드 검색 기능 활용
          <br />
          황금키워드 검색 기능의 경우 대출, 아이폰과 같은 메인키워드를 검색하면
          관련 서브키워드 200개 이상을 제공해줍니다.
          <br />
          <br />
          검색 기능 사용 역시 B지수를 활용합니다.
          <br />
          <br />
          이 처럼 타겟팅을 원하는 애드센스 관련 광고에 대한 메인 키워드나
          쿠팡파트너스 상품을 메인 키워드로 잡고 앞서 B지수에 따라 활용합니다.
          <br />
          <br />
          추가로 숏테일 키워드가 아니라면 검색량이 적더라도, 내용을 풍부하게
          적으면 검색량 보다 많은 유입이 일어납니다.
          <br />
          <br />
          <br />
          3. 게릴라 기능 활용
          <br />
          게릴라 키워드의 경우 CAFE 댓글을 활용하여 자신의 목적에 맞게 외부
          유입을 일으키면 됩니다.
          <br />
          <br />
          <br />
          4. 검색량이 적은 키워드를 먼저 선점하세요.
          <br />
          8월 22일 이미 황금키워드에서는 "갯마을 차차차 촬영지"라는 황금키워드를
          찾아냈습니다.
          <br />
          <Img style={{ height: "500px" }} src="/info14.png" />
          <br />
          <br />
          그리고 일주일 뒤 하루 8만 이상의 높은 검색량이 발생 됐습니다.
          <br />
          <br />
          그럼 해당 키워드로 어떻게 수익을 낼 수 있을까요?
          <br />
          <br />
          해당 키워드는 여성과 30대 이상 검색량이 높은 키워드로
          애드센스/애드포스트 고수익을 기대할 수 있습니다.
          <br />
          <br />
          또한 쿠팡파트너스나 텐핑, 리플알바 등 제휴마케팅을 통해 타겟팅이
          될만한 상품 혹은 머천트를 통해서도 고수익이 가능했을 겁니다.
          <br />
          <br />
          "그럼 네가 하지"라고 반문을 하실 수 있습니다.
          <br />
          <br />
          네, 그게 바로 황금키워드를 만든 이유입니다.
          <br />
          <br />
          개인이 황금키워드의 모든 키워드를 활용할 수 없기 때문입니다.
          <br />
          <br />
          그래서 저보다 시간적 여유가 있거나, 혹은 온라인 마케팅 내공이 높은
          분들이 황금키워드를 활용하길 바라는 마음으로 황금키워드를 개발
          했습니다.
          <br />
          <br />
          이 처럼 황금키워드의 다양한 기능을 활용하여 키워드의 수익화를
          시도해보시기 바랍니다.
          <br />
          <br />
          또한 황금키워드는 지속적인 업데이트 중에 있습니다.
          <br />
          <br />
          업데이트 시에는 무료 체험 안내 메일을 발송해드리고 있습니다.
          <br />
          <br />
          황금키워드를 이용해서 업무 시간은 줄이고, 수익은 높이세요!
          <br />
        </Text>
        {/* <object
          type="text/html"
          width="100%"
          height="420"
          data={"https://www.youtube.com/embed/1EbKv4kA_Ow"}
          allowFullScreen=""
        ></object>{" "} */}
      </TextForm>
      <br></br>
    </Container>
  );
};
