import React from "react";
import styled from "styled-components";
import Table from "Components/Table";
import { Redirect } from "react-router-dom";
import { withCookies, Cookies } from "react-cookie";
import { toast } from "react-toastify";

const Container = styled.div`
  justify-content: center;
`;

const SubListPresenter = () => {
  const cookie = new Cookies({});
  const apiKeyValue = cookie.get("apiKeyValue");
  let expire = true;
  if (apiKeyValue === "false") {
    expire = false;
    toast.error(<div>{"서비스 기간 만료"}</div>, {
      autoClose: 3000,
    });
  }
  return expire ? (
    <Container>
      <Table type="sublist" />
    </Container>
  ) : (
    <Redirect to="/auth" />
  );
};

export default withCookies(SubListPresenter);
