import React, { useState, useEffect } from "react";
// import { BrowserRouter as Route, Redirect, Switch } from "react-router-dom";
import { Route, Redirect, Switch } from "react-router-dom";
import Info from "Components/Info";
import List from "Routes/List";
import SubList from "Routes/SubList";
import CommonList from "Routes/CommonList";
import ProductList from "Routes/ProductList";
import Search from "Routes/Search";
import Auth from "Components/Auth";
import MyPage from "Components/MyPage";
import Privacy from "./Privacy";
import Effect from "./Effect";
import Feed from "./Feed";
import Payment from "./Payment";
import "react-toastify/dist/ReactToastify.css";
import { withCookies, Cookies } from "react-cookie";

const LoggedInRoutes = () => (
  <Switch>
    <Route path="/" exact component={Auth} />
    <Route path="/mypage" exact component={MyPage} />
    <Route path="/list" exact component={List} />
    <Route path="/productlist" exact component={ProductList} />
    <Route path="/commonlist" exact component={CommonList} />
    <Route path="/sublist" exact component={SubList} />
    <Route path="/search" exact component={Search} />
    <Route path="/search/:keyword" exact component={Search} />
    <Route path="/payment" exact component={Payment} />
    <Route path="/info" exact component={Info} />
    <Route path="/feed" exact component={Feed} />
    <Route path="/effect" exact component={Effect} />
    <Route path="/privacy" exact component={Privacy} />
    <Redirect from="*" to="/" />
  </Switch>
);

const LoggedOutRoutes = () => (
  <Switch>
    <Route path="/" exact component={Auth} />
    <Route path="/payment" exact component={Payment} />
    <Route path="/info" exact component={Info} />
    <Route path="/feed" exact component={Feed} />
    <Route path="/effect" exact component={Effect} />
    <Route path="/privacy" exact component={Privacy} />
    <Redirect from="*" to="/" />
  </Switch>
);

const AppRouter = () => {
  const cookie = new Cookies({});
  const [login, setLogin] = useState(true);
  useEffect(() => {
    setLogin(cookie.get("check"));
  });
  return login ? <LoggedInRoutes /> : <LoggedOutRoutes />;
};

// export default AppRouter;
export default withCookies(AppRouter);
