import React from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Helmet from "react-helmet";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
      fontSize: "1rem",
      backgroundColor: "rgba(20, 20, 20, 1)",
      color: "white",
      paddingTop: "50px",
      letterSpacing: "0.01071em",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: "15px 0px 13px",
    fontSize: "1.5rem",
    lineHeight: "2",
    maxWidth: "53%",
    marginTop: "2%",
    marginBottom: "1%",
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
}));

const tiers = [
  {
    title: "3일 이용권",
    price: "0원",
    description: [
      "한 달 내 키워드 제공",
      "일 검색 30회 가능",
      "키워드 저장 기능",
    ],
    buttonText: "구매하기",
    buttonVariant: "contained",
  },
  {
    title: "1개월 이용권",
    price: "20,000원",
    description: [
      "한 달 내 키워드 제공",
      "일 검색 300회 가능",
      "키워드 저장 기능",
    ],
    buttonText: "구매하기",
    buttonVariant: "contained",
    link: "https://smartstore.naver.com/gold-keyword/products/6102346494",
  },
  {
    title: "3개월 이용권",
    price: "50,000원",
    description: [
      "한 달 내 키워드 제공",
      "일 검색 300회 가능",
      "키워드 저장 기능",
    ],
    buttonText: "구매하기",
    // buttonVariant: "outlined",
    buttonVariant: "contained",
    link: "https://smartstore.naver.com/gold-keyword/products/6102355636",
  },
  {
    title: "eBook",
    price: "50,000원",
    description: [
      "노하우 eBook 제공",
      "1개월 이용권 제공",
      <a href="/#/Feed" style={{ color: "#0056b3" }}>
        목차 확인
      </a>,
    ],
    buttonText: "구매하기",
    // buttonVariant: "outlined",
    buttonVariant: "contained",
    link: "https://smartstore.naver.com/gold-keyword/products/6102364455",
  },
];

export default function Price() {
  const classes = useStyles();
  const handleClick = (event, text) => {
    window.gtag_report_conversion("https://toon.at/donate/637274581800830807");
  };

  return (
    <React.Fragment>
      <CssBaseline />
      {/* Hero unit */}
      <Helmet>
        <script>
          {`    
            function gtag_report_conversion(url) {
              var callback = function () {
              if (typeof url != "undefined") { 
                // window.location = url
              }
            }
            window.gtag("event", "conversion", {
              send_to: "AW-963158908/9yzACMyknIQDEPzGossD",
              event_callback: callback,
            }
          ) 
          return false 
          }`}
        </script>
      </Helmet>
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <strong style={{ color: "#FFF20A", fontSize: "1.5rem" }}>
          황금키워드
        </strong>
        는 블로그(애드센스/애드포스트/쿠팡파트너스), 바이럴마케팅,
        스마트스토어를 위한 서비스로 검색량은 많고, 문서수는 적은{" "}
        <strong style={{ color: "#FFF20A", fontSize: "1.5rem" }}>
          최적의 키워드
        </strong>
        를 제공하는 서비스입니다.
        <br></br>
      </Container>
      {/* End hero unit */}
      <Container
        maxWidth="md"
        component="main"
        style={{ color: "#FFF20A", fontSize: "1.2rem", maxWidth: "1300px" }}
      >
        <Grid container spacing={4} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === "Enterprise" ? 12 : 6}
              md={3}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{ align: "center" }}
                  action={tier.title === "Pro" ? null : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      {tier.price}
                    </Typography>
                    <Typography variant="h6" color="textSecondary"></Typography>
                  </div>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant={tier.buttonVariant}
                    color="primary"
                    onClick={(e) => handleClick(e, "clicked")}
                  >
                    <a href={tier.link} target="_blank">
                      <strong>{tier.buttonText}</strong>
                    </a>
                    {/* <strong>{tier.buttonText}</strong> */}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
}
