import axios from "axios";

const api = axios.create({
  // baseURL: "https://api.themoviedb.org/3/",
  baseURL:
    "https://4isx1t1s3m.execute-api.ap-northeast-2.amazonaws.com/gold-keyword/",
  credentials: "include",
  params: {
    // api_key: "b40df0c74124c8eafec65b5922f72459",
    // language: "ko-KR",
    // date: "20-05-2020",
  },
});

export const GraphApi = {
  getGraph: (keyword, date, timeUnit, ages, gender) =>
    api.get("find/graph", {
      params: {
        value: keyword,
        date: date,
        timeUnit: timeUnit,
        ages: ages,
        gender: gender,
      },
      // headers: {
      //   "X-API-Key": apiKey,
      // },
    }),
};

export const keywordSearchApi = {
  findKeyword: (date, apiKey) =>
    api.get("find/date", {
      params: {
        value: date,
      },
      headers: {
        "X-API-Key": apiKey,
      },
    }),
  searchKeyword: (keyword, apiKey) =>
    api.get("find/keyword", {
      params: {
        value: keyword,
      },
      headers: {
        "X-API-Key": apiKey,
      },
    }),
  detailInfoKeyword: (keyword, apiKey) =>
    api.get("find/detailinfo", {
      params: {
        value: keyword,
      },
      headers: {
        "X-API-Key": apiKey,
      },
    }),
  detailInfoWebKeyword: (keyword, apiKey) =>
    api.get("find/detailinfoweb", {
      params: {
        value: keyword,
      },
      headers: {
        "X-API-Key": apiKey,
      },
    }),
  privateKeyword: (date, apiKey) =>
    api.get("find/private", {
      params: {
        value: date,
      },
      headers: {
        "X-API-Key": apiKey,
      },
    }),
  findSubKeyword: (date, apiKey) =>
    api.get("find/coupang", {
      params: {
        value: date,
      },
      headers: {
        "X-API-Key": apiKey,
      },
    }),
  findProductKeyword: (date, apiKey) =>
    api.get("find/product", {
      params: {
        value: date,
      },
      headers: {
        "X-API-Key": apiKey,
      },
    }),
  findCommonKeyword: (date, apiKey) =>
    api.get("find/common", {
      params: {
        value: date,
      },
      headers: {
        "X-API-Key": apiKey,
      },
    }),
};

export const AuthApi = {
  login: (email) =>
    api.get("login", {
      params: {
        email: email,
      },
    }),
  confirm: (email, secretKey) =>
    api.get("login", {
      params: {
        email: email,
        secretKey: secretKey,
      },
    }),
};

export const moviesApi = {
  nowPlaying: () => api.get("movie/now_playing"),
  // nowPlaying: () => api.get("keyword"),
  upcoming: () => api.get("movie/upcoming"),
  popular: () => api.get("movie/popular"),
  movieDetail: (id) =>
    api.get(`movie/${id}`, {
      params: {
        append_to_response: "videos",
      },
    }),
  search: (term) => {
    return api.get("search/movie", {
      params: {
        query: term,
      },
    });
  },
};
