/* eslint-disable no-fallthrough */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { GraphApi } from "api";
import { withCookies } from "react-cookie";
import { toast } from "react-toastify";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import moment from "moment";

// const nowDate = moment().format("YYYY-MM-DD")
const beforeYear = moment().subtract(3, "year").format("YYYY-MM-DD");
const beforeMonth = moment().subtract(30, "days").format("YYYY-MM-DD");
const beforeWeek = moment().subtract(7, "days").format("YYYY-MM-DD");

const Container = styled.div``;

const TXT = styled.div`
  float: right;
  padding-bottom: 0.5%;
`;

const FirstContainer = styled.div`
  padding: 0.2% 0.4% 0.1% 0.4%;
  display: flex;
  justify-content: space-between;
`;
const SecondContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Subject = styled.div`
  font-size: 1.1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  line-height: 1.6;
  padding-bottom: 0.5%;
  padding-left: 0.5%;
`;

const GetGraph = (keyword, date, timeUnit, ages, gender, search, type) => {
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  async function monthGraph() {
    try {
      setLoading(true);
      const {
        data: { results },
      } = await GraphApi.getGraph(keyword, date, timeUnit, ages, gender, type);
      let totalRatio = 0;
      let getResult = [];
      results[0].data.forEach((result) => {
        totalRatio += result.ratio;
      });
      const avCount = search / totalRatio;
      const lastCount =
        search / results[0].data[results[0].data.length - 2].ratio;
      if (type == "date")
        results[0].data.forEach((result) => {
          let period = result.period.substring(5, 11);
          getResult.push({
            period: period,
            count: Math.round(result.ratio * avCount),
          });
        });
      else if (type == "month") {
        results[0].data.forEach((result) => {
          let period = result.period.substring(0, 7);
          getResult.push({
            period: period,
            count: Math.round(result.ratio * lastCount),
          });
        });
        getResult.pop();
      } else if (type == "week")
        results[0].data.forEach((result) => {
          // let period = result.period.substring(0, 11)
          // getResult.push({"period": period, "count": Math.round(result.ratio*lastCount) })
          let period = result.period.substring(0, 11);
          const weekDayName = moment(period).format("ddd");
          // eslint-disable-next-line default-case
          switch (weekDayName) {
            case "Mon":
              getResult[0] = {
                period: "월",
                count: Math.round((result.ratio / totalRatio) * 100),
              };
              break;
            case "Tue":
              getResult[1] = {
                period: "화",
                count: Math.round((result.ratio / totalRatio) * 100),
              };
              break;
            case "Wed":
              getResult[2] = {
                period: "수",
                count: Math.round((result.ratio / totalRatio) * 100),
              };
              break;
            case "Thu":
              getResult[3] = {
                period: "목",
                count: Math.round((result.ratio / totalRatio) * 100),
              };
              break;
            case "Fri":
              getResult[4] = {
                period: "금",
                count: Math.round((result.ratio / totalRatio) * 100),
              };
              break;
            case "Sat":
              getResult[5] = {
                period: "토",
                count: Math.round((result.ratio / totalRatio) * 100),
              };
              break;
            case "Sun":
              getResult[6] = {
                period: "일",
                count: Math.round((result.ratio / totalRatio) * 100),
              };
              break;
          }
        });
      else if (type == "gender_age_result") {
        results[0].data.forEach((result) => {
          getResult.push({ period: result.period, count: result.ratio });
        });
      } else if (type == "10") {
        results[0].data.forEach((result) => {
          getResult.push({ period: result.period, count: result.ratio });
        });
      } else if (type == "20") {
        results[0].data.forEach((result) => {
          getResult.push({ period: result.period, count: result.ratio });
        });
      } else if (type == "30") {
        results[0].data.forEach((result) => {
          getResult.push({ period: result.period, count: result.ratio });
        });
      } else if (type == "40") {
        results[0].data.forEach((result) => {
          getResult.push({ period: result.period, count: result.ratio });
        });
      } else if (type == "50") {
        results[0].data.forEach((result) => {
          getResult.push({ period: result.period, count: result.ratio });
        });
      } else if (type == "gender" && gender == "m") {
        results[0].data.forEach((result) => {
          getResult.push({ period: result.period, count: result.ratio });
        });
      } else if (type == "gender" && gender == "f") {
        results[0].data.forEach((result) => {
          getResult.push({ period: result.period, count: result.ratio });
        });
      }
      setResult(getResult);
    } catch (err) {
      //   toast.error(
      //     <div>
      //       {"잠시 시스템에 문제가 발생 됐습니다. 지속될 시에 메일주시기 바랍니다."}
      //     </div>,
      //   {
      //     autoClose: 5000,
      //   }
      // );
      setError(true);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    monthGraph();
  }, [keyword]);
  return { result, loading, error };
};

const Graph = ({ keyword, search }) => {
  const age_data = [];
  const gender_data = [];
  const week_data = [];
  const month_result = GetGraph(
    keyword,
    beforeMonth,
    "date",
    "None",
    "None",
    search,
    "date"
  );
  const month_data = month_result;

  const year_result = GetGraph(
    keyword,
    beforeYear,
    "month",
    "None",
    "None",
    search,
    "month"
  );
  const year_data = year_result.result;

  const week_result = GetGraph(
    keyword,
    beforeWeek,
    "date",
    "None",
    "None",
    search,
    "week"
  );
  week_result.result.forEach((result) => {
    week_data.push({ period: result.period, count: result.count });
  });

  const gender_age_result = GetGraph(
    keyword,
    beforeMonth,
    "date",
    "None",
    "None",
    search,
    "gender_age_result"
  );

  const age_10_result = GetGraph(
    keyword,
    beforeMonth,
    "date",
    "10",
    "None",
    search,
    "10"
  );
  const age_20_result = GetGraph(
    keyword,
    beforeMonth,
    "date",
    "20",
    "None",
    search,
    "20"
  );
  const age_30_result = GetGraph(
    keyword,
    beforeMonth,
    "date",
    "30",
    "None",
    search,
    "30"
  );
  const age_40_result = GetGraph(
    keyword,
    beforeMonth,
    "date",
    "40",
    "None",
    search,
    "40"
  );
  const age_50_result = GetGraph(
    keyword,
    beforeMonth,
    "date",
    "50",
    "None",
    search,
    "50"
  );

  if (
    gender_age_result.result.length != 0 &&
    age_10_result.result.length != 0 &&
    age_20_result.result.length != 0 &&
    age_30_result.result.length != 0 &&
    age_40_result.result.length != 0 &&
    age_50_result.result.length != 0
  ) {
    if (
      gender_age_result.result.length != true &&
      age_10_result.error != true &&
      age_20_result.error != true &&
      age_30_result.error != true &&
      age_40_result.error != true &&
      age_50_result.error != true
    ) {
      let age_10_count = 0;
      let age_20_count = 0;
      let age_30_count = 0;
      let age_40_count = 0;
      let age_50_count = 0;
      try {
        for (let i = 0; i < gender_age_result.result.length; i++) {
          for (let age_10 = 0; age_10 < age_10_result.result.length; age_10++) {
            if (
              gender_age_result.result[i].period ==
              age_10_result.result[age_10].period
            ) {
              age_10_count += Math.abs(
                gender_age_result.result[i].count -
                  age_10_result.result[age_10].count
              );
            }
          }
          for (let age_20 = 0; age_20 < age_20_result.result.length; age_20++) {
            if (
              gender_age_result.result[i].period ==
              age_20_result.result[age_20].period
            ) {
              age_20_count += Math.abs(
                gender_age_result.result[i].count -
                  age_20_result.result[age_20].count
              );
            }
          }
          for (let age_30 = 0; age_30 < age_30_result.result.length; age_30++) {
            if (
              gender_age_result.result[i].period ==
              age_30_result.result[age_30].period
            ) {
              age_30_count += Math.abs(
                gender_age_result.result[i].count -
                  age_30_result.result[age_30].count
              );
            }
          }
          for (let age_40 = 0; age_40 < age_40_result.result.length; age_40++) {
            if (
              gender_age_result.result[i].period ==
              age_40_result.result[age_40].period
            ) {
              age_40_count += Math.abs(
                gender_age_result.result[i].count -
                  age_40_result.result[age_40].count
              );
            }
          }
          for (let age_50 = 0; age_50 < age_50_result.result.length; age_50++) {
            if (
              gender_age_result.result[i].period ==
              age_50_result.result[age_50].period
            ) {
              age_50_count += Math.abs(
                gender_age_result.result[i].count -
                  age_50_result.result[age_50].count
              );
            }
          }
        }
        age_10_count = Math.pow(age_10_count, 1.7);
        age_20_count = Math.pow(age_20_count, 1.7);
        age_30_count = Math.pow(age_30_count, 1.7);
        age_40_count = Math.pow(age_40_count, 1.7);
        age_50_count = Math.pow(age_50_count, 1.7);

        const age_total_count =
          age_10_count +
          age_20_count +
          age_30_count +
          age_40_count +
          age_50_count;
        const age_totla_ratio =
          age_total_count / age_10_count +
          age_total_count / age_20_count +
          age_total_count / age_30_count +
          age_total_count / age_40_count +
          age_total_count / age_50_count;
        age_data.push({
          period: "18세 이하",
          count: Math.round(
            (age_total_count / age_10_count / age_totla_ratio) * 100
          ),
        });
        age_data.push({
          period: "20대",
          count: Math.round(
            (age_total_count / age_20_count / age_totla_ratio) * 100
          ),
        });
        age_data.push({
          period: "30대",
          count: Math.round(
            (age_total_count / age_30_count / age_totla_ratio) * 100
          ),
        });
        age_data.push({
          period: "40대",
          count: Math.round(
            (age_total_count / age_40_count / age_totla_ratio) * 100
          ),
        });
        age_data.push({
          period: "50대 이상",
          count: Math.round(
            (age_total_count / age_50_count / age_totla_ratio) * 100
          ),
        });
      } catch (err) {
        age_data.push({ period: 0, count: 0 });
      }
    } else {
      age_data.push({ period: 0, count: 0 });
    }
  } else {
    age_data.push({ period: 0, count: 0 });
  }

  const gender_m_result = GetGraph(
    keyword,
    beforeMonth,
    "date",
    "None",
    "m",
    search,
    "gender"
  );
  const gender_f_result = GetGraph(
    keyword,
    beforeMonth,
    "date",
    "None",
    "f",
    search,
    "gender"
  );
  if (
    gender_age_result.result.length != 0 &&
    gender_m_result.result.length != 0 &&
    gender_f_result.result.length != 0
  ) {
    if (
      gender_age_result.error != true &&
      gender_m_result.error != true &&
      gender_f_result.error != true
    ) {
      let gender_m_count = 0;
      let gender_f_count = 0;
      try {
        for (let i = 0; i < gender_age_result.result.length; i++) {
          for (
            let gender_m = 0;
            gender_m < gender_m_result.result.length;
            gender_m++
          ) {
            if (
              gender_age_result.result[i].period ==
              gender_m_result.result[gender_m].period
            ) {
              gender_m_count += Math.abs(
                gender_age_result.result[i].count -
                  gender_m_result.result[gender_m].count
              );
            }
          }
          for (
            let gender_f = 0;
            gender_f < gender_f_result.result.length;
            gender_f++
          ) {
            if (
              gender_age_result.result[i].period ==
              gender_f_result.result[gender_f].period
            ) {
              gender_f_count += Math.abs(
                gender_age_result.result[i].count -
                  gender_f_result.result[gender_f].count
              );
            }
          }
        }
        gender_m_count = Math.pow(gender_m_count, 1.7);
        gender_f_count = Math.pow(gender_f_count, 1.7);
        const gender_total_count = gender_m_count + gender_f_count;
        const gender_totla_ratio =
          gender_total_count / gender_m_count +
          gender_total_count / gender_f_count;
        gender_data.push({
          period: "남성",
          count: Math.round(
            (gender_total_count / gender_m_count / gender_totla_ratio) * 100
          ),
        });
        gender_data.push({
          period: "여성",
          count: Math.round(
            (gender_total_count / gender_f_count / gender_totla_ratio) * 100
          ),
        });
      } catch (err) {
        // console.log(err)
        gender_data.push({ period: 0, count: 0 });
      }
    }
  } else {
    gender_data.push({ period: 0, count: 0 });
  }

  return month_data.loading ? (
    <Container></Container>
  ) : (
    <Container>
      <FirstContainer>
        <div>
          <Subject>[최근 1달 검색량(추정)]</Subject>
          <LineChart width={850} height={200} data={month_data.result}>
            <Line type="monotone" dataKey="count" stroke="#01579B" />
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="period" />
            <YAxis />
            <Tooltip />
          </LineChart>
        </div>
        <div>
          <Subject>[최근 3년 검색량(추정)]</Subject>
          <LineChart width={850} height={200} data={year_data}>
            <Line type="monotone" dataKey="count" stroke="#01579B" />
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="period" />
            <YAxis />
            <Tooltip />
          </LineChart>
        </div>
      </FirstContainer>
      <SecondContainer>
        <div>
          <Subject>[최근 일주일 검색 통계]</Subject>
          <BarChart width={500} height={180} data={week_data}>
            <Bar type="monotone" dataKey="count" fill="#01579B" />
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="period" />
            <YAxis />
          </BarChart>
        </div>
        <div>
          <Subject>[성별 검색 통계]</Subject>
          <BarChart width={500} height={180} data={gender_data}>
            <Bar type="monotone" dataKey="count" fill="#01579B" />
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="period" />
            <YAxis />
          </BarChart>
        </div>
        <div>
          <Subject>[연령별 검색 통계]</Subject>
          <BarChart width={500} height={180} data={age_data}>
            <Bar type="monotone" dataKey="count" fill="#01579B" />
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="period" />
            <YAxis />
          </BarChart>
        </div>
      </SecondContainer>
      <TXT>
        ⚠️검색량이 적거나, 단기간 급상승한 키워드의 경우 일부 통계가 불안정할 수
        있습니다.
      </TXT>
    </Container>
  );
};

export default withCookies(Graph);
