import React from "react";
import styled from "styled-components";
const Container = styled.div`
  width: 100%;
  margin: 0 auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextForm = styled.div`
  font-size: 1.1rem;
  width: 50%;
  margin: 1% auto 1% auto;
  @media only screen and (max-width: 1028px) {
    width: 80%;
  }
`;

const Text = styled.div`
  margin-bottom: 1rem;
  letter-spacing: 0.1rem;
  line-height: 1.5rem;
`;

const Image = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto 0 auto;
`;

const Img = styled.img`
  width: 100%;
`;

const Point = styled.div`
  margin: 0 auto 0 auto;
`;

export default () => {
  return (
    <Container>
      <TextForm>
        <Text>
          <p data-ke-size="size18">
            <br />
            [온라인 비즈니스 분야 베스트 서적에 소개된 황금키워드]
            <br />
            <br />
            <Image>
              <Img src="/book1.png" />
            </Image>
            <br />
            이 책의 저자 권호영님은 10년간 블로그를 운영하며 수시로 바뀌는
            블로그 로직 및 상위노출의 비밀을 직접 파헤쳤습니다. 세계여행
            인플루언서로 활동하며 여행뿐 아니라 영어 및 도서, IT분야까지 설렵,
            강의 및 클럽하우스와 메이크맥 플랫폼을 통해 기록하는 법과 블로그
            운영 팁을 전수하고 계십니다.
            <br />
            <br />
            이 책에서는 키워드 도구들을 소개하는데, 그 중 황금키워드를 소개하고
            있습니다.
            <br />
            <br />
            황금키워드는 전문가에게도 인정받은 키워드 플랫폼입니다.
            <br />
            참고:&nbsp;
            <a href={`https://url.kr/5jhdn7`} target="_blank">
              https://url.kr/5jhdn7
            </a>
            <br />
            <br />
            <br />
            [황금키워드 효과]
            <br />
            현재 저는 1년 넘게 황금키워드를 사용하여, 하루 30분 이내 남는 시간을
            활용해서 구글 애드센스와 쿠팡파트너스 그리고 텐핑과 같은
            제휴마케팅을 통해 수도권 아파트 월세 이상을 벌고 있습니다.
            <br />
            <br />
            물론 수익이 언제까지 지속될 지는 알 수 없습니다.
            <br />
            <br />
            한 번에 "펑" 하고 날아갈 수도 있는 아파트입니다. 따라서 잘 지키고
            확장하는 게 중요햡니다.
            <br />
            <br />
            저뿐만 아니라 황금키워드를 활용하는 제 지인의 경우 평일 하루 1시간
            정도 시간을 투자해서 애드센스, 애드포스트, 쿠팡파트너스를 통해 한
            달만에 월 수익 30만원 이상을 달성했습니다.
            <br />
            <br />
            수익은 꾸준히 증가하고 있습니다.
            <br />
            <br />
            아래는 그림은 블로그를 생전 처음하는 지인의 방문수와 애드센스
            수익입니다.
            <br />
            블로그 구성과 애드센스 승인까진 제가 해줬습니다.
            <br />
            <br />
          </p>
          <Image>
            <Img src="/tistory.png" />
          </Image>
          <br />
          <br />
          또한 아래 구글 서치콘솔 그림을 보시면 블로그 포스팅은 대부분 구글에서
          상위 노출되고 있습니다.
          <br />
          <br />
          구글의 경우 상위 노출되면 오랜 기간 게재 순위를 유지시켜 주는 것은
          다들 아실 겁니다.
          <br />
          <br />
          따라서 효율이 떨어지는 일회성의 지속적인 포스팅이 필요 없습니다.
          <br />
          <br />
          <Image>
            <Img src="/effect1.png" />
          </Image>
          <br />
          <br />
          이 뿐만 아니라 황금키워드는 티스토리나 워드프레스가 아닌 네이버
          블로그에도 적용됩니다.
          <br />
          <br />
          테스트로 황금키워드를 이용하여 키워드를 찾고 네이버 블로그에 포스팅
          했습니다.
          <br />
          <br />
          역시 글 1개로 단 번에 일 조회수 330회를 만들었습니다. <br />
          <br />
          주목할 점은 이슈성 글이 아닌 쿠팡파트너스를 통한 수익 창출이 가능한
          글이었다는 것입니다. <br />
          <br />
          <Image>
            <Img src="/naver_2.png" />
          </Image>
          <br />
          또한 게릴라 기능을 이용하여 N사 혹은 D사의 상위 노출 항목에 CAFE를
          찾고 본인의 블로그 혹은 제휴마케팅을 위한 댓글 게릴라도 쉽게 진행할 수
          있습니다.
          <br />
          <br />
          <Image>
            <Img src="/info12.png" />
          </Image>
          <br />
          <br />
          제휴마케팅을 위한 댓글 게릴라 10개 내외 30분 가량 작업을 통해 2주일
          동안 25만원 이상의 수익을 수익을 올렸습니다.
          <br />
          <br />
          물론 쿠팡파트너스라면 "상품" 혹은 CPC, CPA와 같은 제휴마케팅이라면
          "머천트" 선정이 중요하고, 마케팅에 대한 감각이 있어야 합니다.
          <br />
          <br />
          언제까지 해당 글이 상위 노출될 지는 모르지만, 머천트만 지속된다면
          비슷한 방식으로 꾸준한 수익을 낼 수 있습니다.
          <br />
          <br />
          <Image>
            <Img src="/info13.png" />
          </Image>
          <p data-ke-size="size18">
            <br />
            <b>[황금키워드를 만든 이유는?]</b>
            <br />
            애드센스/쿠팡파트너스/애드포스트/스마트스토어 등 온라인을 통한 수익
            창출은 90% 이상 키워드로 정해집니다.
            <br />
            <br />
            저도 각종 키워드 프로그램과 서비스들을 이용 했었습니다.
            <br />
            <br />
            그러나 각 프로그램들에 부족한 점을 찾았으며, 보완해서 직접 쓰기 위해
            개발을 시작했습니다.
            <br />
            <br />
            그 덕분에 현재는 수도권 아파트 월세 이상 수익을 내고 있습니다.
            <br />
            <br />
            앞서 말씀드린 것처럼 제 지인들에게도 소개해줬고, 한 달만에
            20~30만원의 수익을 내고 있습니다.
            <br />
            <br />
            <br />
            [황금키워드를 공개한 이유]
            <br />
            아무리 좋은 키워드를 찾았어도 모든 카테고리의 키워드를 활용하기에는
            어려움이 있기 때문입니다.
            <br />
            <br />
            황금키워드에 블로그/상품키워드 리스트를 보면 하루 최소 1000~2000개의
            키워드가 제공됩니다.
            <br />
            <br />
            키워드의 카테고리는 매우 다양합니다. 인기 있는 상품들부터 게임, 앱,
            경제 그리고 각종 이슈들까지 다양한 카테고리의 키워드를 보실 수
            있습니다.
            <br />
            <br />
            그렇기 때문에 황금키워드들을 저보다 더 잘 활용할 수 있는 분이라면
            유용할 것이라는 판단을 했습니다.
            <br />
            <br />
            또한 다른 키워드 프로그램 혹은 키워드를 판매하는 분들의 경우, 적게는
            수만원부터 수십만원까지 판매되고 있습니다.
            <br />
            <br />
            그리고 다수의 무료 키워드 프로그램들은 여러분들이 검색한 키워드들을
            저장하고, 가치 있는 키워드들을 유료로 판매하기도 합니다.
            <br />
            <br />
            아마 힘들게 좋은 키워드를 찾아도 금세 그 키워드로 포스팅한
            블로그들이나 상품들이 늘어나는 경험도 있으실 겁니다.
            <br />
            <br />
            황금키워드는 사용자의 검색 키워드를 절대로 저장하지 않으며, 유료로
            판매도 하지 않습니다.
            <br />
            <br />
            심지어 황금키워드 회원분들 중에서도 크몽 등 지식 판매 사이트에서
            황금키워드를 이용하여 키워드를 판매하거나, 마케터 본인의 네이버
            블로그 성장을 위해 사용하는 것을 보기도 했습니다.
            <br />
            <br />
            물론 황금키워드도 만능은 아닙니다. 모든 포스팅을 상위 노출 시킬 수는
            없습니다.
            <br />
            <br />
            그러나 황금키워드를 통해 키워드 서칭 시간을 줄일 수 있고, 광고비를
            아낄 수 있으며, 상위 노출 확률을 높일 수 있습니다.
            <br />
            <br />
            현재 황금키워드 재가입률은 30% 이상입니다.
            <br />
            <br />
            아래 분은 서비스 오픈부터 8개월 동안 황금키워드를 이용하고 계십니다.
            <br />
            <Image>
              <Img
                style={{ height: "250px", weight: "100%" }}
                src="/feed8.png"
              />
            </Image>
            <br />
            또한 대다수 황금키워드 가입자 분들은 한 달 이용 후 아래와 같이 3개월
            연장 이용을 하고 있습니다.
            <br />
            <Image>
              <Img
                style={{ height: "200px", weight: "100%" }}
                src="/relay2.png"
              />
            </Image>
            <br />
            <Image>
              <Img
                style={{ height: "200px", weight: "100%" }}
                src="/relay3.png"
              />
            </Image>
            <br />
            <br />
            <Image>
              <Img
                style={{ height: "300px", weight: "100%" }}
                src="/relay4.png"
              />
            </Image>
            <br />
            이 처럼 황금키워드의 효과는 높은 재가입률로 입증 됐습니다.
            <br />
            <br />
            처음 목표는 황금키워드를 무료로 오픈하고, 애드센스로 수익을 창출하려
            했습니다.
            <br />
            <br />
            하지만 다른 키워드 서비스와 다르게 다수의 서버와 많은 데이터 저장
            비용이 발생되어, 현실적으로 애드센스 수익만으로는 운영이 불가하다고
            판단했습니다.
            <br />
            <br />
            또한 이용자가 늘어날수록 이 서비스의 가치는 떨어집니다.
            <br />
            <br />
            그러나 월천무새 유튜버 등 사기꾼(?)들처럼 별 거 아닌 정보나 서비스를
            포장해서 팔지 않기 위해 3일 무료 이용 기간을 제공합니다.
            <br />
            <br />
            3일 동안 써보시고 효과가 있다고 판단되시면, 후기를 남기고 한 달 더
            이용해보시기 바랍니다.
            <br />
            <br />
            그리고 한 달 뒤에도 효과를 느끼신다면 구매해주시기 바랍니다.
            <br />
            <br />
            다만 무료 체험 사용자분들은 기능에 일정 부분 제한이 있는 점
            참고하시기 바랍니다.
            <br />
            <br />
            [리뷰 모음]&nbsp;
            <br />
            스마트스토어 리뷰:&nbsp;
            <a
              href={`https://smartstore.naver.com/gold-keyword`}
              target="_blank"
            >
              https://smartstore.naver.com/gold-keyword
            </a>
            <br></br>
            <Image>
              <Img src="/feed1.png" />
            </Image>
            <br />
            <Image>
              <Img src="/feed2.png" />
            </Image>
            <br />
            <Image>
              <Img src="/feed9.png" />
            </Image>
            <br />
            <Image>
              <Img src="/feed10.png" />
            </Image>
            <br />
            <Image>
              <Img style={{ height: "120px" }} src="/feed3.png" />
            </Image>
            <br />
            <Image>
              <Img style={{ height: "120px" }} src="/feed4.png" />
            </Image>
            <br />
            <Image>
              <Img style={{ height: "1000px" }} src="/feed5.png" />
            </Image>
            <br />
            <Image>
              <Img style={{ height: "600px" }} src="/feed6.png" />
            </Image>
            <br />
            <Image>
              <Img style={{ height: "200px" }} src="/feed7.png" />
            </Image>
            <br />
            <Image>
              <Img style={{ height: "800px" }} src="/feed11.png" />
            </Image>
            <br />
            <Image>
              <Img style={{ height: "450px" }} src="/feed12.png" />
            </Image>
            <br />
          </p>
          물론 "될놈될, 안될안" 입니다. 맹신은 금물입니다.
          <br />
          <br />
          현재 황금키워드의 재가입률은 30% 이상입니다.
          <br />
          <br />
          대략 가입자 세 분 중 한 분은 성공했고, 나머지 두 분은 포기했다는 것을
          알 수 있습니다.
          <br />
          <br />
          열정과 감각이 있는 분이라면 충분히 도전해 볼 만한 수치라고 생각됩니다.
          <br />
          <br />
          여러분들의 건승을 빕니다.
          <br />
        </Text>
      </TextForm>
    </Container>
  );
};
