import React from "react";
import styled from "styled-components";

const Footer = styled.footer`
  display: flex;
  width: 100%;
  /* height: 7%; */
  justify-content: center;
  text-transform: uppercase;
  font-weight: 600;
  align-items: flex-end;
  padding: 1% 3% 0% 3%;
`;

const Footer2 = styled.footer`
  display: flex;
  width: 100%;
  /* height: 7%; */
  justify-content: center;
  text-transform: uppercase;
  font-weight: 600;
  align-items: flex-end;
  padding: 0 3% 1% 1%;
`;

const List = styled.ul`
  display: flex;
  font-size: 0.9rem;
`;

const ListItem = styled.li`
  &:not(:last-child) {
    margin-right: 16px;
  }
`;

const Link = styled.a`
  color: ${(props) => props.theme.darkBlueColor};
`;

const Copyright = styled.span`
  width: 400px;
  font-size: 0.9rem;
  margin-left: 1%;
  color: ${(props) => props.theme.darkGreyColor};
`;

export default () => (
  <>
    <Footer className="footer">
      <List>
        <ListItem>
          <Link href="/#/Effect">효과</Link>
        </ListItem>
        <ListItem>
          <Link href="/#/Info">기능</Link>
        </ListItem>
        <ListItem>
          <Link href="/#/Payment">구매하기</Link>
        </ListItem>
        <ListItem>
          <Link href="/#/Feed">노하우 eBook</Link>
        </ListItem>
        <ListItem>
          <Link href="/#/Privacy">개인정보처리방침</Link>
        </ListItem>
      </List>
      <Copyright>Copyright © 2020 황금키워드. All rights reserved.</Copyright>
    </Footer>
    <Footer>
      <List>
        오픈런 | 사업자등록번호 297-69-00431 | 대표 권혜민 | 서울 구로구 경인로
        662 디큐브시티 15층 | 070-8095-1632
      </List>
    </Footer>
  </>
);
