import React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 1%;
`;

const TextForm = styled.div`
  width: 50%;
  font-size: 1.1rem;
`;

const Text = styled.div`
  margin-bottom: 1rem;
  letter-spacing: 0.1rem;
  line-height: 1.3rem;
`;

const Title = styled.div`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

export default () => {
  return (
    <Container>
      <Helmet>
        <title>황금 키워드</title>
      </Helmet>
      <Title>개인정보처리방침</Title>
      <TextForm>
        <Text>1. 수집하는 개인정보 항목</Text>
        <Text>
          [황금키워드](‘https://gold-keyword.info’이하 ‘황금키워드’) 은(는)
          다음의 목적을 위하여 개인정보를 처리하고 있으며, 다음의 목적 이외의
          용도로는 이용하지 않습니다.
          <Text>
            - 고객 가입의사 확인, 서비스 제공에 따른 본인 식별.인증, 회원자격
            유지.
          </Text>
        </Text>
        <Text>2. 개인정보의 처리 및 보유 기간</Text>
        <Text>
          ①[황금키워드](‘https://gold-keyword.info’이하 ‘황금키워드’) 은(는)
          정보주체로부터 개인정보를 수집할 때 동의 받은 개인정보 보유․이용기간
          또는 법령에 따른 개인정보 보유․이용기간 내에서 개인정보를
          처리․보유합니다.
        </Text>
        <Text>② 구체적인 개인정보 처리 및 보유 기간은 다음과 같습니다.</Text>
        <Text>- 로그인/세션 만료 시</Text>
        <Text>- 마지막 로그인 시점이 6개월 전이면 계정은 삭제됩니다.</Text>
        <Text>
          3. 정보주체와 법정대리인의 권리·의무 및 그 행사방법 이용자는
          개인정보주체로써 다음과 같은 권리를 행사할 수 있습니다.
        </Text>
        <Text>
          ① 정보주체는 황금키워드(‘https://gold-keyword.info’이하 ‘황금키워드)
          에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수
          있습니다. 1. 개인정보 열람요구 2. 오류 등이 있을 경우 정정 요구 3.
          삭제요구 4. 처리정지 요구
        </Text>
        <Text>4. 처리하는 개인정보의 항목 작성</Text>
        <Text>
          ① [황금키워드]('https://gold-keyword.info'이하 '황금키워드')은(는)
          다음의 개인정보 항목을 처리하고 있습니다.
        </Text>
        <Text>1. [로그인] - 필수항목 : 이메일</Text>
        <Text>
          5. 개인정보의 파기 ('황금키워드')은(는) 원칙적으로 개인정보 처리목적이
          달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한
          및 방법은 다음과 같습니다.
        </Text>
        <Text>
          파기절차 이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의
          경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된
          후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한
          경우가 아니고서는 다른 목적으로 이용되지 않습니다. 파기기한 이용자의
          개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의
          종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의
          폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의
          처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를
          파기합니다.
        </Text>
        <Text>6. 개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항</Text>
        <Text>
          ①황금키워드 은 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고
          수시로 불러오는 ‘쿠기(cookie)’를 사용합니다. ② 쿠키는 웹사이트를
          운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는
          소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도
          합니다. 가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹
          사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을
          파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다. 나. 쿠키의
          설치•운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의
          옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다. 다. 쿠키 저장을
          거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.
        </Text>
        <Text>7. 개인정보 보호책임자 작성</Text>
        <Text>
          ① 황금키워드(‘https://gold-keyword.info’이하 ‘황금키워드) 은(는)
          개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한
          정보주체의 불만처리 및 피해구제 등을 위하여 개인정보 보호책임자를
          지정하고 있습니다. (문의: master@gold-keyword.info)
        </Text>
        <Text>8. 개인정보 처리방침 변경</Text>
        <Text>
          ①이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른
          변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일
          전부터 공지사항을 통하여 고지할 것입니다.
        </Text>
        <Text>
          9. 개인정보의 안전성 확보 조치 ('황금키워드')은(는) 개인정보보호법
          제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적
          조치를 하고 있습니다.
        </Text>
        <Text>
          1. 정기적인 자체 감사 실시 개인정보 취급 관련 안정성 확보를 위해
          정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.
          <Text>
            2. 개인정보 취급 직원의 최소화 및 교육 개인정보를 취급하는 직원을
            지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을
            시행하고 있습니다.{" "}
          </Text>
          <Text>
            3. 내부관리계획의 수립 및 시행 개인정보의 안전한 처리를 위하여
            내부관리계획을 수립하고 시행하고 있습니다.{" "}
          </Text>
          <Text>
            4. 해킹 등에 대비한 기술적 대책 ('황금키워드')은 해킹이나 컴퓨터
            바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여
            보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이
            통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고
            있습니다.{" "}
          </Text>
          <Text>
            5. 비인가자에 대한 출입 통제 개인정보를 보관하고 있는 물리적 보관
            장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고
            있습니다.
          </Text>
        </Text>
      </TextForm>
    </Container>
  );
};
