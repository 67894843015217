import React, { useState } from "react";
import styled from "styled-components";
import { Cookies } from "react-cookie";

const Container = styled.div`
  width: 90%;
  height: 37px;
  color: black;
  position: relative;
  margin: 0.5% 0.5% 0.5% 0.5%;
  z-index: 999;
  display: flex;
`;

const Select = styled.select`
  width: 150px;
  height: 2rem;
  margin: auto 0 auto 0;
`;

const Text = styled.div`
  color: white;
  font-size: 1.3rem;
  align-items: center;
  margin-top: 8px;
`;

function getFormatDateToday(confirm) {
  if (confirm === "goldKeyword") {
    let date = new Date();
    let year = date.getFullYear(); //yyyy
    let month = 1 + date.getMonth(); //M
    let day = date.getDate();
    month = month >= 10 ? month : "0" + month; //month 두자리로 저장
    day = day >= 10 ? day : "0" + day; //day 두자리로 저장
    return `${day}-${month}-${year}`;
  } else {
    let date = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    let year = date.getFullYear(); //yyyy
    let month = 1 + date.getMonth(); //M
    let day = date.getDate();
    month = month >= 10 ? month : "0" + month; //month 두자리로 저장
    day = day >= 10 ? day : "0" + day; //day 두자리로 저장
    return `${day}-${month}-${year}`;
  }
}

function getFormatDate() {
  const dateArray = [];
  for (let i = 0; i < 32; i++) {
    let date = new Date();
    let today = new Date();
    date.setDate(today.getDate() - i);
    let year = date.getFullYear();
    let month = 1 + date.getMonth();
    month = month >= 10 ? month : "0" + month;
    let day = date.getDate();
    day = day >= 10 ? day : "0" + day;
    dateArray.push(`${day}-${month}-${year}`);
  }
  return dateArray;
}

const DatePick = () => {
  const cookie = new Cookies({});
  const confirm = cookie.get("confirm");
  const options = getFormatDate();
  const [date, setDate] = useState(getFormatDateToday(confirm));
  const dates = options;
  const addDate = dates.map((date) => date);
  const handleDateChange = (e) => {
    setDate(e.target.value);
  };
  const Selector = () =>
    confirm === "goldKeyword" ? (
      <Container>
        <Select
          defaultValue={date}
          onChange={handleDateChange}
          className="browser-default custom-select"
        >
          {addDate.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </Select>
      </Container>
    ) : (
      <Container>
        <Text>무료 사용자의 경우 일주일 전 키워드만 제공합니다.</Text>
      </Container>
    );

  // return { date, Selector };
  return { date, Selector };
};

export default DatePick;
